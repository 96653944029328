exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-kitchen-remodel-02242024-js": () => import("./../../../src/pages/blog/kitchen-remodel-02242024.js" /* webpackChunkName: "component---src-pages-blog-kitchen-remodel-02242024-js" */),
  "component---src-pages-blog-siding-03182024-js": () => import("./../../../src/pages/blog/siding-03182024.js" /* webpackChunkName: "component---src-pages-blog-siding-03182024-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-decks-patios-js": () => import("./../../../src/pages/decks-patios.js" /* webpackChunkName: "component---src-pages-decks-patios-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-remodeling-js": () => import("./../../../src/pages/kitchen-remodeling.js" /* webpackChunkName: "component---src-pages-kitchen-remodeling-js" */),
  "component---src-pages-siding-js": () => import("./../../../src/pages/siding.js" /* webpackChunkName: "component---src-pages-siding-js" */),
  "component---src-pages-windows-js": () => import("./../../../src/pages/windows.js" /* webpackChunkName: "component---src-pages-windows-js" */)
}

